import shortUUID from 'short-uuid';
import { validate as validateUUID, version as versionOfUUID } from 'uuid';

import { type OptionalString } from '@/common/models';

const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
const encoder = shortUUID(alphabet);

export function isValidUUID(value: OptionalString): value is string {
  return !!value && validateUUID(value) && versionOfUUID(value) === 4;
}

function isValidPublicID(value: OptionalString): value is string {
  return !!value && encoder.validate(value);
}

// Check if the passed in value is a valid UUID or public ID.
export function isValidID(value: OptionalString): value is string {
  return !!value && (isValidUUID(value) || isValidPublicID(value));
}

function valueToID(value: OptionalString | object | unknown): OptionalString {
  if (!value) return;
  if (typeof value === 'string') return value;
  if (typeof value === 'object' && 'id' in value && typeof value.id === 'string') return value.id;

  return null;
}

/**
 * Translates a full length UUID string into a smaller public ID.
 */
export function encodeToPublicID(value: OptionalString | object | unknown): string {
  const uuid = valueToID(value);
  if (!isValidUUID(uuid)) return '';

  return encoder.fromUUID(uuid);
}

/**
 * Translates a public ID string into its equivalent full UUID.
 */
export function decodeFromPublicID(value: OptionalString | object): OptionalString {
  const publicID = valueToID(value);

  // Trickery for dev/support to use the UUID from DynamoDB if needed
  if (isValidUUID(publicID)) return publicID;
  if (!publicID || !isValidPublicID(publicID)) return;

  return encoder.toUUID(publicID);
}
